<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>Answer each of the questions listed below. Select all options that apply.</p>

      <div>
        <p>
          For a hydrogen-like atom, which of these transitions would result in the
          <span class="text-bold">absorption</span> of light?
        </p>

        <div v-for="option in options" :key="'pt-1-' + option.value">
          <v-checkbox v-model="inputs.input1" :value="option.value" class="mb-0 mt-0 pl-8">
            <template v-slot:label>
              <stemble-latex :content="option.text" />
            </template>
          </v-checkbox>
        </div>
      </div>

      <div>
        <p>
          For a hydrogen-like atom, which of these transitions would result in the
          <span class="text-bold">emission</span> of light?
        </p>

        <div v-for="option in options" :key="'pt-2-' + option.value">
          <v-checkbox v-model="inputs.input2" :value="option.value" class="mb-0 mt-0 pl-8">
            <template v-slot:label>
              <stemble-latex :content="option.text" />
            </template>
          </v-checkbox>
        </div>
      </div>

      <div>
        <p>
          Considering only the magnitude, which transition(s) is/are associated with the
          <span class="text-bold">largest</span> change in energy?
        </p>

        <div v-for="option in options" :key="'pt-3-' + option.value">
          <v-checkbox v-model="inputs.input3" :value="option.value" class="mb-0 mt-0 pl-8">
            <template v-slot:label>
              <stemble-latex :content="option.text" />
            </template>
          </v-checkbox>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question14',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: [],
      },
      options: [
        {text: '$n=3 \\text{ to } n=2$', value: 'a'},
        {text: '$n=3 \\text{ to } n=5$', value: 'b'},
        {text: '$n=1 \\text{ to } n=3$', value: 'c'},
        {text: '$n=2 \\text{ to } n=1$', value: 'd'},
      ],
    };
  },
};
</script>
